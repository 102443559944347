/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "../services";
import { SET_ERROR } from "../errorHandler";
import { pagination } from "../constants/pagination";

const getDefaultState = () => {
  return {
    participants: [],
    events: [],
    currentEvent: null,
    filters: {
      search: "",
      registered: undefined
    },
    sortBy: "createdAt",
    sortDesc: true,
    commentSortBy: "createdAt",
    commentDesc: true,
    likeSortBy: "createdAt",
    likeDesc: true
  };
};
const state = getDefaultState();

const getters = {
  getParticipants: state => state.participants,
  getEvents: state => state.events,
  getCurrentEvent: state => state.currentEvent,

  getFilterSearch: state => state.filters.search,
  getFilterRegistered: state => state.filters.registered,

  getSortBy: state => state.sortBy,
  getSortDesc: state => state.sortDesc
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_EVENTS(state, events) {
    state.events = events;
  },
  SET_PARTICIPANTS(state, participants) {
    state.participants = participants;
  },
  SET_CURRENT_EVENT(state, event) {
    state.currentEvent = event;
  },
  SET_FILTER_SEARCH(state, search) {
    state.filters.search = search;
  },
  SET_FILTER_REGISTERED(state, value) {
    state.filters.registered = value;
  },
  SET_SORT_BY(state, sortBy) {
    state.sortBy = sortBy;
  },
  SET_SORT_DESC(state, sortDesc) {
    state.sortDesc = sortDesc;
  },
  TOGGLE_REGISTRATION(state, { userId, value }) {
    const index = state.participants.findIndex(p => p.userId._id === userId);
    if (index === -1) return;
    state.participants[index].registered = value;
  }
};

const actions = {
  async retrieveEvents(
    { commit, getters, rootGetters },
    { initial, hasPagination } = {}
  ) {
    try {
      const parameters = {
        search: getters.getFilterSearch,
        isReported: getters.getFilterReported,
        administratorId: rootGetters["filter/getAdministratorSelected"]
          ? rootGetters["filter/getAdministratorSelected"]._id
          : undefined,
        skip: hasPagination
          ? initial
            ? pagination.skip
            : getters.getEvents.length
          : pagination.skip,
        limit: hasPagination ? pagination.limit : -1,
        sort: `${getters.getSortBy} ${getters.getSortDesc ? "asc" : "desc"}`,
        type: "event"
      };
      if (getters.getFilterCondominium) {
        parameters.condominiumId = getters.getFilterCondominium._id;
      }
      const response = await services.EventService.retrieveEvents(parameters);
      commit(
        "SET_EVENTS",
        initial || !hasPagination
          ? response
          : [...getters.getEvents, ...response]
      );
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveEvent({ commit }, parameters) {
    try {
      const response = await services.EventService.retrieveEvent(parameters);
      commit("SET_CURRENT_EVENT", response.event);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, { fallbackRoute: "Events" });
      return Promise.reject();
    }
  },
  async createEvent({ commit }, { condominiumId, payload }) {
    try {
      const response = await services.EventService.createEvent(
        { condominiumId },
        payload
      );
      return response;
    } catch (error) {
      console.error(error.response?.body || error);
      return Promise.reject();
    }
  },
  async updateEvent({ commit }, { condominiumId, postId, payload }) {
    try {
      const response = await services.EventService.updatePost({
        condominiumId,
        postId,
        payload
      });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async addPhotos({ commit }, { postId, payload }) {
    try {
      await services.EventService.addPhotos(postId, payload);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deletePhoto({ commit }, { postId, photoId }) {
    try {
      await services.EventService.deletePhoto(postId, photoId);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async addDocuments({ commit }, { postId, payload }) {
    try {
      await services.EventService.addDocuments(postId, payload);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deleteDocument({ commit }, { postId, documentId }) {
    try {
      await services.EventService.deleteDocument(postId, documentId);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async retrieveParticipants(
    { commit, getters },
    { eventId, hasPagination = false, initial = false, ...extraParams } = {}
  ) {
    try {
      const parameters = {
        eventId,
        search: getters.getFilterSearch,
        registered: getters.getFilterRegistered,
        skip: hasPagination
          ? initial
            ? pagination.skip
            : getters.getEvents.length
          : pagination.skip,
        limit: hasPagination ? pagination.limit : -1,
        sort: `${getters.getSortBy} ${getters.getSortDesc ? "asc" : "desc"}`,
        ...extraParams
      };
      const response = await services.EventService.retrieveParticipants({
        ...parameters,
        ...parameters,
        eventId
      });
      commit(
        "SET_PARTICIPANTS",
        initial || !hasPagination ? response : [...getters.getPa, ...response]
      );
    } catch (error) {
      commit("alert/SET_ALERT", {}, { root: true });
    }
  },
  async registerParticipant({ commit }, params) {
    try {
      params.value = true;
      await services.EventService.registerParticipant(params);
      commit("TOGGLE_REGISTRATION", params);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async unregisterParticipant({ commit }, params) {
    try {
      params.value = false;
      await services.EventService.unregisterParticipant(params);
      commit("TOGGLE_REGISTRATION", params);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  remindEvent({ commit }, { postId }) {
    return new Promise((resolve, reject) => {
      try {
        const response = services.EventService.remindEvent(postId);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }
};

export default {
  namespaced: true,
  strict: true,
  state,
  getters,
  mutations,
  actions
};
