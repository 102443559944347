export const userStatus = [
  {
    value: "active",
    label: "active",
    variant: "green"
  },
  {
    value: "invited",
    label: "invited",
    variant: "yellow"
  },
  {
    value: "notverified",
    label: "notverified",
    variant: "cyan"
  },
  {
    value: "suspended",
    label: "suspended",
    variant: "secondary"
  }
];

export const userCommunicationGroupFilters = {
  status: ["active"],
  groups: []
};

export const userStatusVariant = {
  active: "green",
  invited: "yellow",
  notverified: "cyan",
  suspended: "secondary"
};
