import Vue from "vue";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import {
  required,
  alpha,
  email,
  min,
  max,
  integer,
  length
} from "vee-validate/dist/rules";
import store from "../store";
import i18n from "./i18n";
import it from "vee-validate/dist/locale/it.json";
import en from "vee-validate/dist/locale/en.json";
import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";

/** DEFINE COMPONENTS */
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

/** LOCALIZE */
localize({ it, en });

extend("required", { ...required });

extend("alpha", { ...alpha });

extend("email", { ...email });

extend("min", { ...min });

extend("max", { ...max });

extend("integer", { ...integer });

extend("length", { ...length });

/** CUSTOM RULES */
extend("select", {
  message: () => {
    return "Please select an option";
  },
  validate(value) {
    return !isEmpty(value);
  }
});
extend("phone", {
  message: (field, values) => {
    // override the field name.
    // values._field_ = i18n.t(`fields.${field}`);
    return i18n.t(`validations.messages.${values._rule_}`, values);
  },
  validate(value) {
    const pattern = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
    return pattern.test(value);
  }
});

extend("password", {
  message() {
    return i18n.t("validations.password");
  },
  validate(value) {
    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&])(?=.{8,})/;
    return pattern.test(value);
  }
});

extend("fullName", {
  message(fieldName, { _value_ }) {
    if (_value_.split(" ").length > 2) return "Too many spaces";
    else return `Please enter your full name`;
  },
  validate(value) {
    const pattern = /^[a-zA-Z]+(?: [a-zA-Z]+){1,1}$/;
    return pattern.test(value);
  }
});

extend("checkboxRequired", {
  message() {
    return `Must be checked.`;
  },
  validate(value) {
    return value !== false;
  }
});

extend("groupCheckboxRequired", {
  message: fieldName => {
    return `Please select one or more ${fieldName}.`;
  },
  validate(value, args) {
    return args.group.findIndex(item => item.enable === true) > -1;
  },
  params: ["group"]
});

extend("lowerCase", {
  message(fieldName) {
    return `${fieldName} must be in lower case.`;
  },
  validate(value) {
    return value === value.toLowerCase();
  }
});

extend("placeRequired", {
  message: (fieldName, args) => {
    return args.optionLength > 0
      ? "Please select an option"
      : `${fieldName} is not a valid.`;
  },
  validate(value, args) {
    return args.text !== "";
  },
  params: ["text", "optionLength"]
});

extend("date", {
  message: () => {
    return i18n.t(`validations.messages.time`);
  },
  validate(value, args) {
    if (!value) return true;
    const [day, month, year] = value.split("/");
    const date = dayjs()
      .set("year", year)
      .set("month", month - 1)
      .set("date", day);
    if (args[0]) return date.format(args[0]) === value;
    else return date.isValid();
  }
});

extend("time", {
  message: () => {
    return i18n.t(`validations.messages.time`);
  },
  validate(value, args) {
    if (!value) return true;
    const [hour, minute] = value.split(":").map(parseFloat);
    const date = dayjs()
      .set("hour", hour)
      .set("minute", minute);
    if (args[0]) return date.format(args[0]) === value;
    else return date.isValid();
  }
});

let VALIDATE_LOCALE = store.getters["general/getAppLang"];
localize(VALIDATE_LOCALE);

Object.defineProperty(Vue.prototype, "validateLocale", {
  get() {
    return VALIDATE_LOCALE;
  },
  set(val) {
    VALIDATE_LOCALE = val;
    localize(VALIDATE_LOCALE);
  }
});
