import swaggerClient from "./../plugins/swagger-client";

export default class EventService {
  createEvent(params, body) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Posts.createPost(
          params,
          swaggerClient.buildRequestBody(body),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  updatePost({ condominiumId, postId, payload }) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Posts.updatePost(
          { postId, condominiumId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveEvents(params) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Events.retrieveEvents(
          params,
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveParticipants(params) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Events.retrieveEventParticipants(
          params,
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  participate(eventId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Events.participateInEvent(
          { eventId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  remindEvent(postId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Events.remindEvent(
          { postId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  registerParticipant(params) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Events.registerInEvent(
          params,
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  unregisterParticipant(params) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Events.unregisterFromEvent(
          params,
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  addPhotos(postId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Posts.addPostPhotos(
          { postId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deletePhoto(postId, photoId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Posts.deletePostPhoto(
          { postId, photoId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  addDocuments(postId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Posts.addPostDocuments(
          { postId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deleteDocument(postId, documentId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Documents.deletePostDocument(
          { postId, documentId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
