import swaggerClient from "./../plugins/swagger-client";
export default class ParametersService {
  createCategory(payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Categories.createCategory(
          {},
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveCategories(parameters = {}) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Categories.retrieveCategories(
          parameters,
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  updateCategory(categoryId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Categories.updateCategory(
          { categoryId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  assignCategory(categoryId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Categories.assignCategory(
          { categoryId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  uploadLogo(administratorId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Administrators.uploadLogo(
          { administratorId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deleteLogo(administratorId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Administrators.deleteLogo(
          { administratorId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
