import swaggerClient from "./../plugins/swagger-client";

export default class MaintainerIssueService {
  retrieveUserIssues(filters) {
    const parameters = filters || {};
    return swaggerClient.swagger
      .then(client => {
        return client.apis.Issues.retrieveUserIssues(
          parameters,
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
