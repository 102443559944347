import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";
import { isAppTypeOneBoard, isAppTypeDomi } from "./helpers/settings";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "Dashboard" },
    component: () => import("./views/MainLayout.vue"),
    children: [
      {
        path: "user-account",
        name: "UserAccount",
        meta: {
          requiresAuth: true
        },
        component: () => import("./views/UserAccount.vue")
      },
      {
        path: "condominiums",
        name: "Condominiums",
        meta: {
          requiresAuth: true
        },
        beforeEnter: async (to, from, next) => {
          try {
            if (store.getters["auth/isDomiUser"])
              await store.dispatch("filter/retrieveActiveAdministrators");
            await store.dispatch("condominium/retrieveCondominiumsByFilters");

            next();
          } catch {}
        },
        component: () => import("./views/Condominiums.vue")
      },
      {
        path: "condominium-management/:condominiumId",
        name: "CondominiumManagement",
        meta: {
          requiresAuth: true
        },
        beforeEnter: async (to, from, next) => {
          const condominiumId = to.params.condominiumId;
          await store.dispatch(
            "condominium/retrieveCondominium",
            condominiumId
          );
          await store.dispatch("condominium/retrieveMetrics");
          next();
        },
        component: () => import("./views/CondominiumManagement.vue")
      },
      {
        path: "groups",
        name: "Groups",
        meta: { requiresAuth: true },
        beforeEnter: async (to, from, next) => {
          if (isAppTypeOneBoard) {
            if (store.getters["auth/isDomiUser"])
              await store.dispatch("filter/retrieveActiveAdministrators");
            next();
          } else {
            handleNoPermissions();
          }
        },
        component: () => import("./views/Groups.vue")
      },
      {
        path: "parameters",
        name: "Parameters",
        meta: { requiresAuth: true },
        beforeEnter: async (to, from, next) => {
          if (isAppTypeOneBoard) {
            if (store.getters["auth/isDomiUser"])
              await store.dispatch("filter/retrieveActiveAdministrators");
            next();
          } else {
            handleNoPermissions();
          }
        },
        component: () => import("./views/Parameters.vue")
      },
      {
        path: "group-management/:groupId",
        name: "GroupManagement",
        meta: {
          requiresAuth: true
        },
        beforeEnter: async (to, from, next) => {
          try {
            if (isAppTypeOneBoard) {
              await store.dispatch("groups/retrieveGroup", to.params.groupId);

              await store.dispatch("groups/retrieveGroupUsers");

              next();
            } else {
              handleNoPermissions();
            }
          } catch {}
        },
        component: () => import("./views/GroupManagement.vue")
      },
      {
        path: "issues",
        name: "Issues",
        meta: {
          requiresAuth: true
        },
        beforeEnter: async (to, from, next) => {
          try {
            if (store.getters["auth/isDomiUser"]) {
              await store.dispatch("filter/retrieveActiveAdministrators");
            }
            if (isAppTypeOneBoard) {
              await store.dispatch("parameters/retrieveCategories");
            }
            // await store.dispatch("condominium/retrieveCondominiums");
            // await store.dispatch("issue/retrieveUserIssues");
            next();
          } catch {}
        },
        component: () => import("./views/Issues.vue")
      },
      {
        path: "issues/:id",
        name: "Issues",
        meta: {
          requiresAuth: true
        },
        beforeEnter: async (to, from, next) => {
          try {
            if (store.getters["auth/isDomiUser"]) {
              await store.dispatch("filter/retrieveActiveAdministrators");
            }
            if (isAppTypeOneBoard) {
              await store.dispatch("parameters/retrieveCategories");
            }
            // await store.dispatch("condominium/retrieveCondominiums");
            // await store.dispatch("issue/retrieveUserIssues");
            next();
          } catch {}
        },
        component: () => import("./views/Issues.vue")
      },
      {
        path: "maintainers",
        name: "Maintainers",
        meta: {
          requiresAuth: true
        },
        beforeEnter: async (to, from, next) => {
          try {
            if (isAppTypeDomi) {
              if (store.getters["auth/isDomiUser"]) {
                await store.dispatch("filter/retrieveActiveAdministrators");
              }
              await store.dispatch("condominium/retrieveCondominiums");
              await store.dispatch("maintainer/retrieveMaintainers");
              await store.dispatch("maintainer/retrieveAdminMaintainers");
              next();
            } else {
              handleNoPermissions();
            }
          } catch {}
        },
        component: () => import("./views/Maintainers.vue")
      },
      {
        path: "domi-users",
        name: "DomiUsers",
        meta: {
          requiresAuth: true
        },
        beforeEnter: (to, from, next) => {
          const userInfo = store.getters["auth/getAdminInfo"];
          if (userInfo.type === "domi") {
            store
              .dispatch("domi/retrieveDomiUsers")
              .then(() => {
                next();
              })
              .catch(() => {});
          } else {
            handleNoPermissions();
          }
        },
        component: () => import("./views/DomiUsers.vue")
      },
      {
        path: "administrators",
        name: "AdminUsers",
        meta: {
          requiresAuth: true
        },
        beforeEnter: (to, from, next) => {
          const userInfo = store.getters["auth/getAdminInfo"];
          if (userInfo.type === "domi") {
            store
              .dispatch("admin/retrieveAdminUsers")
              .then(() => {
                next();
              })
              .catch(() => {});
          } else {
            handleNoPermissions();
          }
        },
        component: () => import("./views/AdminUsers.vue")
      },
      {
        path: "consumers",
        name: "ConsumerUsers",
        meta: {
          requiresAuth: true
        },
        beforeEnter: (to, from, next) => {
          const userInfo = store.getters["auth/getAdminInfo"];
          console.log(userInfo);
          if (userInfo.type !== "domi") {
            handleNoPermissions();
          } else {
            next();
          }
        },
        component: () => import("./views/ConsumerUsers.vue")
      },
      {
        path: "consumers/:id/details",
        name: "ConsumerDetails",
        meta: {
          requiresAuth: true
        },
        beforeEnter: (to, from, next) => {
          const userInfo = store.getters["auth/getAdminInfo"];
          if (userInfo.type === "domi") {
            store
              .dispatch("consumer/retrieveConsumerUser", to.params.id)
              .then(() => {
                next();
              })
              .catch(() => {});
          } else {
            handleNoPermissions();
          }
        },
        component: () => import("./components/Consumer/ConsumerDetails.vue")
      },
      {
        path: "maintainer-users",
        name: "MaintainerUsers",
        meta: {
          requiresAuth: true
        },
        beforeEnter: async (to, from, next) => {
          try {
            const userInfo = store.getters["auth/getAdminInfo"];
            if (isAppTypeOneBoard || userInfo.type === "domi") {
              next();
            } else {
              handleNoPermissions();
            }
          } catch {}
        },
        component: () => import("./views/MaintainerUsers.vue")
      },
      {
        path: "posts",
        name: "Posts",
        meta: {
          requiresAuth: true
        },
        beforeEnter: async (to, from, next) => {
          try {
            const userInfo = store.getters["auth/getAdminInfo"];
            if (isAppTypeOneBoard || userInfo.type === "domi") {
              await store.dispatch("condominium/retrieveCondominiums");
              await store.dispatch("post/retrieveUserPosts", {
                initial: true,
                hasPagination: true
              });
              next();
            } else {
              handleNoPermissions();
            }
          } catch {}
        },
        component: () => import("./views/Posts.vue")
      },
      {
        path: "posts/details/:postId/:condominiumId",
        name: "PostDetails",
        props: true,
        meta: {
          requiresAuth: true
        },
        beforeEnter: async (to, from, next) => {
          try {
            const userInfo = store.getters["auth/getAdminInfo"];
            if (isAppTypeOneBoard || userInfo.type === "domi") {
              const parameters = {
                postId: to.params.postId,
                condominiumId: to.params.condominiumId
              };
              await store.dispatch("post/retrievePost", parameters);
              next();
            } else {
              handleNoPermissions();
            }
          } catch {}
        },
        component: () => import("./views/PostDetails.vue")
      },
      {
        path: "dashboard",
        name: "Dashboard",
        meta: {
          requiresAuth: true
        },
        beforeEnter: async (to, from, next) => {
          try {
            store.commit("loading/SET_LOADING", true);
            store.commit("condominium/SET_CURRENT_CONDOMINIUM", null);
            if (store.getters["auth/isDomiUser"]) {
              await store.dispatch("filter/retrieveActiveAdministrators");
              // await store.dispatch("maintainer/retrieveMaintainers");
              // await store.dispatch("consumer/retrieveConsumerUsers");
            }
            const userId = store.getters["auth/getUserId"];
            await store.dispatch("auth/retrieveUser", userId);
            await store.dispatch("condominium/retrieveCondominiums");
            await store.dispatch("condominium/retrieveMetrics");

            next();
          } catch {
          } finally {
            store.commit("loading/SET_LOADING", false);
          }
        },
        component: () => import("./views/Dashboard.vue")
      },
      {
        path: "communications",
        name: "Communications",
        meta: {
          requiresAuth: true
        },
        beforeEnter: async (to, from, next) => {
          try {
            if (store.getters["auth/isDomiUser"])
              await store.dispatch("filter/retrieveActiveAdministrators");

            next();
          } catch {}
        },
        component: () => import("./views/Communications.vue")
      },
      {
        path: "communications/new",
        name: "NewCommunication",
        meta: {
          requiresAuth: true
        },
        component: () => import("./views/CommunicationCreation.vue")
      },
      {
        path: "communications/edit/:condId/:comId",
        name: "EditCommunication",
        meta: {
          requiresAuth: true
        },
        beforeEnter: (to, from, next) => {
          store.commit("communication/RESET");
          var ids = to.params;
          store.commit("loader/SET_LOADER", {});
          store
            .dispatch("communication/retrieveCommunication", {
              condominiumId: ids.condId,
              communicationId: ids.comId
            })
            .then(() => {
              const recipients = store.getters["communication/getRecipients"];
              store.commit(
                "communication/SET_RECIPIENTS_SELECTION",
                recipients
              );
            })
            .then(() => {
              next();
            })
            .catch(() => {})
            .finally(() => store.commit("loader/RESET"));
        },
        component: () => import("./views/CommunicationCreation.vue")
      },
      {
        path: "communications/groups/new",
        name: "NewGroupCommunication",
        meta: {
          requiresAuth: true
        },
        component: () => import("./views/CommunicationGroupCreation.vue")
      },
      {
        path: "communications/groups/:communicationId",
        name: "EditGroupCommunication",
        meta: {
          requiresAuth: true
        },
        beforeEnter: (to, from, next) => {
          store.commit("communication/RESET");
          var { communicationId } = to.params;
          store.commit("loader/SET_LOADER", {});
          store
            .dispatch("communication/retrieveCommunicationPure", {
              communicationId
            })
            .then(() => {
              const recipients = store.getters["communication/getRecipients"];
              store.commit(
                "communication/SET_RECIPIENTS_SELECTION",
                recipients
              );
            })
            .then(() => {
              next();
            })
            .catch(() => {})
            .finally(() => store.commit("loader/RESET"));
        },
        component: () => import("./views/CommunicationGroupCreation.vue")
      },
      {
        path: "events",
        name: "Events",
        meta: {
          requiresAuth: true
        },
        component: () => import("./views/Events.vue")
      },
      {
        path: "event/create",
        name: "CreateEvent",
        meta: {
          requiresAuth: true
        },
        component: () => import("./views/CreateEvent.vue")
      },
      {
        path: "/condominium/:condominiumId/event/:postId",
        name: "EventDetails",
        meta: {
          requiresAuth: true
        },
        component: () => import("./views/EventDetails.vue")
      },
      {
        path: "condominium/:condominiumId/event/:postId/participants",
        name: "EventParticipants",
        meta: {
          requiresAuth: true
        },
        component: () => import("./views/EventParticipants.vue")
      },
      {
        path: "maintainer/issues",
        name: "MaintainerIssues",
        meta: { requiresAuth: true },
        beforeEnter: async (to, from, next) => {
          try {
            if (["login", null].includes(from.name)) {
              next();
              return;
            }
            store.commit("maintainerIssues/RESET");
            store.commit("loader/SET_LOADER", {});
            const userInfo = store.getters["auth/getAdminInfo"];

            await store.dispatch("userMaintainer/retrieveMaintainerUser", {
              userId: userInfo._id
            });
            next();
          } catch (error) {
            console.log(error);
          } finally {
            store.commit("loader/RESET");
          }
        },
        component: () => import("./views/MaintainerIssues.vue")
      },
      {
        path: "maintainer/issues/:id",
        name: "MaintainerIssues",
        meta: { requiresAuth: true },
        beforeEnter: async (to, from, next) => {
          if (["login", null].includes(from.name)) {
            next();
            return;
          }
          store.commit("maintainerIssues/RESET");
          store.commit("loader/SET_LOADER", {});
          try {
            const userInfo = store.getters["auth/getAdminInfo"];

            await store.dispatch("parameters/retrieveCategories");
            await store.dispatch("condominium/retrieveCondominiums");
            await store.dispatch("maintainerIssues/retrieveUserIssues");

            await store.dispatch("userMaintainer/retrieveMaintainerUser", {
              userId: userInfo._id
            });

            next();
          } catch (error) {
            console.log(error);
          } finally {
            store.commit("loader/RESET");
          }
        },
        component: () => import("./views/MaintainerIssues.vue")
      },
      {
        path: "/test",
        name: "Test",
        component: () => import("./views/Test.vue")
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      requiresAuth: false
    },
    component: () => import("./views/Login.vue")
  },
  {
    path: "/password-recovery",
    name: "PasswordRecovery",
    meta: {
      requiresAuth: false
    },
    component: () => import("./views/PasswordRecovery.vue")
  },
  {
    path: "/token-recovery",
    name: "TokenRecovery",
    meta: {
      requiresAuth: false
    },
    component: () => import("./views/TokenRecovery.vue")
  },
  {
    path: "/recovery/:token",
    name: "ChangePassword",
    props: true,
    component: () => import("./views/ChangePassword.vue")
  },
  {
    path: "/confirm/:token",
    name: "SetPassword",
    props: true,
    component: () => import("./views/SetPassword.vue")
  },
  {
    path: "/communication/:token",
    name: "CommunicationDetails",
    props: true,
    component: () => import("./views/CommunicationDetails.vue")
  },
  {
    path: "*",
    name: "404",
    component: () => import("./components/Base/Error404.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    };
  }
});

router.beforeEach((to, from, next) => {
  if (!to.params.hasAlert) {
    store.commit("alert/RESET");
  }

  store.commit("loader/SET_LOADER", {});
  store.commit("confirm/RESET");

  const isLoggedIn = store.getters["auth/isLoggedIn"];
  if (isLoggedIn && to.name === "Login") {
    router.replace({ name: "Dashboard", params: to.params });
  } else if (to.meta.requiresAuth) {
    if (isLoggedIn) {
      next({ params: to.params });
    } else {
      next({ name: "Login", params: to.params });
      store.commit("loader/RESET");
    }
  } else {
    next({ params: to.params });
  }
});

router.afterEach((to, from) => {
  store.commit("loader/RESET");
});

const handleNoPermissions = () => {
  store.commit(
    "alert/SET_ALERT",
    {
      titleText: "dialog.error.pageNotFoundTitle",
      messageText: "dialog.error.pageNotFoundMsg"
    },
    { root: true }
  );

  router
    .push({ name: "Dashboard", params: { hasAlert: true } })
    .catch(() => {});
};

export default router;
