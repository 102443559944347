import ConfigProvider from "./plugins/config-provider";
import orderBy from "lodash/orderBy";

const SERVER_ERROR_CODES = {
  UNAUTHORIZED: 401
};

const ISSUE_TYPE_ICONS = {
  electric: "lightning-fill",
  hydraulic: "droplet-fill",
  tv: "tv-fill",
  lift: "bricks",
  automation: "bezier",
  intercom: "back",
  telephone: "telephone-fill",
  cleaning: "brush-fill",
  waste: "wrench",
  disinfestation: "flag-fill",
  green: "suit-club-fill",
  other: "question-diamond-fill"
};

const ISSUE_STATUS_VARIANTS = {
  new: "cyan",
  incomplete: "yellow",
  "assigned-maintainer": "green",
  "assigned-administrator": "orange",
  closed: "dark",
  "maintainer-closed": "cyan-500",
  suspended: "secondary",
  deleted: "danger"
};

const MAINTAINER_TYPES = [
  "electric",
  "hydraulic",
  "tv",
  "lift",
  "automation",
  "intercom",
  "telephone",
  "cleaning",
  "waste",
  "disinfestation",
  "green",
  "other",
  "painting",
  "bricklayer",
  "caretaker",
  "babysitter",
  "dogsitter",
  "appliancerepair",
  "blacksmith",
  "locksmith",
  "tiler",
  "mover",
  "windows",
  "furniture",
  "glazier",
  "airconditioning",
  "interiordesign"
];

const PARSE_JWT = token => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

const IS_FUNCTION = functionToCheck => {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
};

const GET_THANK_PAGE = user => {
  const adminThank = ConfigProvider.value("administratorThankPage");
  const maintainerThank = ConfigProvider.value("maintainerThankPage");
  if (user === "administrator") return adminThank;
  else if (user === "maintainer") return maintainerThank;
  else return null;
};

const SORT_FILTERS = (filters, param) => {
  const orderedFilters = orderBy(filters, [param], ["asc"]);
  return orderedFilters;
};

const BASE64_TO_FILE = (dataURI, name) => {
  var arr = dataURI.split(",");
  var mime = arr[0].match(/:(.*?);/)[1];
  var ext = arr[0].match(/(data:image\/(.*?);base64)/)[2];
  var bstr = atob(arr[1]);
  var n = bstr.length;
  var u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], `${name}.${ext}`, { type: mime });
};

const FILE_TO_BASE64 = file => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      resolve(reader.result);
    };
    reader.onerror = function(error) {
      console.log("Error: ", error);
    };
  });
};

const RESIZE_IMG = (base64Str, format) => {
  return new Promise(resolve => {
    const img = new Image();
    img.src = base64Str;
    img.onerror = console.error;
    img.onabort = console.error;
    img.onloadstart = console.log;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const MAX_WIDTH = 750;
      const MAX_HEIGHT = 750;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL());
    };
  });
};

export {
  SERVER_ERROR_CODES,
  ISSUE_TYPE_ICONS,
  ISSUE_STATUS_VARIANTS,
  MAINTAINER_TYPES,
  IS_FUNCTION,
  PARSE_JWT,
  GET_THANK_PAGE,
  SORT_FILTERS,
  BASE64_TO_FILE,
  FILE_TO_BASE64,
  RESIZE_IMG
};
