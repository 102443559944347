/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";
import { SET_ERROR } from "./../errorHandler";
import { isAppTypeOneBoard } from "./../helpers/settings";
import { pagination } from "../constants/pagination";

const getDefaultState = () => {
  return {
    maintainerUsers: [],
    currentMaintainerUser: {
      generalInfo: {}
    },
    currentMaintainerCondominiums: [],
    filters: {
      search: "",
      userStatus: null
    },
    sortBy: "createdAt",
    sortDesc: true
  };
};
const state = getDefaultState();

const getters = {
  getMaintainerUsers: state => state.maintainerUsers,
  getCurrentMaintainerUser: state => state.currentMaintainerUser,
  getCurrentMaintainerCondominiums: state =>
    state.currentMaintainerCondominiums || [],

  getFilterSearch: state => state.filters.search,
  getFilterUserStatus: state => state.filters.userStatus,

  getSortBy: state => state.sortBy,
  getSortDesc: state => state.sortDesc
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_MAINTAINER_USERS(state, maintainerUsers) {
    state.maintainerUsers = maintainerUsers;
  },
  SET_CURRENT_MAINTAINER_USER(state, currentMaintainerUser) {
    state.currentMaintainerUser = currentMaintainerUser;
  },
  SET_CURRENT_MAINTAINER_CONDOMINIUMS(state, currentMaintainerCondominiums) {
    state.currentMaintainerCondominiums = currentMaintainerCondominiums;
  },
  SET_FILTER_SEARCH(state, search) {
    state.filters.search = search;
  },
  SET_FILTER_USER_STATUS(state, userStatus) {
    state.filters.userStatus = userStatus;
  },
  SET_SORT_BY(state, sortBy) {
    state.sortBy = sortBy;
  },
  SET_SORT_DESC(state, sortDesc) {
    state.sortDesc = sortDesc;
  }
};

const actions = {
  async retrieveMaintainerUsers(
    { commit, getters, rootGetters },
    { initial, hasPagination } = {}
  ) {
    try {
      const parameters = {
        type: "maintainer",
        search: getters.getFilterSearch,
        skip: initial ? pagination.skip : getters.getMaintainerUsers.length,
        limit: hasPagination ? pagination.limit : -1,
        sort: `${getters.getSortBy} ${getters.getSortDesc ? "asc" : "desc"}`
      };
      if (getters.getFilterUserStatus) {
        parameters.status = getters.getFilterUserStatus || undefined;
      }
      if (isAppTypeOneBoard)
        parameters.administratorId =
          rootGetters["auth/getAdminInfo"].type === "domi"
            ? rootGetters["filter/getAdministratorSelected"]?._id
            : rootGetters["auth/getAdminInfo"]._id;

      const response = await services.UserService.retrieveUsers(parameters);
      commit(
        "SET_MAINTAINER_USERS",
        initial || !hasPagination
          ? response
          : [...getters.getMaintainerUsers, ...response]
      );
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveMaintainerUser(
    { commit, dispatch },
    { userId, needMaintainer = true }
  ) {
    try {
      const response = await services.UserService.retrieveUser(userId);
      commit("SET_CURRENT_MAINTAINER_USER", {
        ...response.user,
        generalInfo: {}
      });
      if (needMaintainer) await dispatch("retrieveMaintainer");
    } catch (error) {
      console.log(error?.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveMaintainers({ commit, getters }) {
    try {
      const parameters = {
        search: getters.getFilterSearch
      };
      if (getters.getFilterUserStatus) {
        parameters.status = getters.getFilterUserStatus;
      }
      const response = await services.MaintainerService.retrieveMaintainers(
        parameters
      );
      commit("SET_MAINTAINER_USERS", response);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveMaintainer({ commit, getters, rootGetters }) {
    const userInfo = getters.getCurrentMaintainerUser;
    const administratorId =
      rootGetters["auth/getAdminInfo"].type === "administrator"
        ? rootGetters["auth/getAdminInfo"]._id
        : rootGetters["filter/getAdministratorSelected"]?._id;
    const response = await services.MaintainerService.retrieveMaintainer(
      userInfo.profile.maintainerId,
      administratorId
    );
    commit("SET_CURRENT_MAINTAINER_USER", {
      ...userInfo,
      generalInfo: response.maintainer
    });

    commit("SET_CURRENT_MAINTAINER_CONDOMINIUMS", response.condominiumsList);
  }
};

export default {
  namespaced: true,
  strict: true,
  state,
  getters,
  mutations,
  actions
};
