/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";
import { SET_ERROR } from "./../errorHandler";

const getDefaultState = () => {
  return {
    condominiums: [],
    filteredCondominiums: [],
    activeCondominiums: [],
    currentCondominium: {},
    condominiumUsers: [],
    condominiumIssues: [],
    condominiumCommunications: [],
    condominiumCategories: [],
    condominiumMaintainers: [],
    filters: {
      search: "",
      condominiumStatus: null
    },
    sortBy: {
      active: "name",
      pending: "name",
      free: "name",
      details: "name"
    },
    sortDesc: {
      active: false,
      pending: false,
      free: false,
      details: false
    },
    activeTab: 0,
    metrics: {}
  };
};
const state = getDefaultState();

const getters = {
  getCondominiums: state => state.condominiums,
  getFilteredCondominiums: state => state.filteredCondominiums,
  getActiveCondominiums: state => state.activeCondominiums,
  getCurrentCondominium: state => state.currentCondominium,
  getCondominiumUsers: state => state.condominiumUsers,
  getCondominiumIssues: state => state.condominiumIssues,
  getCondominiumCommunications: state => state.condominiumCommunications,
  getCondominiumCategories: state => state.condominiumCategories,
  getCondominiumMaintainers: state => state.condominiumMaintainers,

  getFilterSearch: state => state.filters.search,
  getFilterCondominiumStatus: state => state.filters.condominiumStatus,

  getSortByActive: state => state.sortBy.active,
  getSortByPending: state => state.sortBy.pending,
  getSortByFree: state => state.sortBy.free,
  getSortByDetails: state => state.sortBy.details,
  getSortDescActive: state => state.sortDesc.active,
  getSortDescPending: state => state.sortDesc.pending,
  getSortDescFree: state => state.sortDesc.free,
  getSortDescDetails: state => state.sortDesc.details,

  getActiveTab: state => state.activeTab,
  getMetrics: state => state.metrics
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_CONDOMINIUMS(state, condominiums) {
    state.condominiums = condominiums;
  },
  SET_FILTERED_CONDOMINIUMS(state, condominiums) {
    state.filteredCondominiums = condominiums;
  },
  SET_ACTIVE_CONDOMINIUMS(state, condominiums) {
    state.activeCondominiums = condominiums;
  },
  SET_CURRENT_CONDOMINIUM(state, condominium) {
    state.currentCondominium = condominium;
  },
  SET_CONDOMINIUM_USERS(state, condominiumUsers) {
    state.condominiumUsers = condominiumUsers;
  },
  SET_CONDOMINIUM_ISSUES(state, condominiumIssues) {
    state.condominiumIssues = condominiumIssues;
  },
  SET_CONDOMINIUM_COMMUNICATIONS(state, condominiumCommunications) {
    state.condominiumCommunications = condominiumCommunications;
  },
  SET_CONDOMINIUM_CATEGORIES(state, condominiumCategories) {
    state.condominiumCategories = condominiumCategories;
  },
  SET_CONDOMINIUM_MAINTAINERS(state, condominiumMaintainers) {
    state.condominiumMaintainers = condominiumMaintainers;
  },
  SET_FILTER_SEARCH(state, search) {
    state.filters.search = search;
  },
  SET_FILTER_CONDOMINIUM_STATUS(state, condominiumStatus) {
    state.filters.condominiumStatus = condominiumStatus;
  },
  SET_SORT_BY_ACTIVE(state, sortBy) {
    state.sortBy.active = sortBy;
  },
  SET_SORT_BY_PENDING(state, sortBy) {
    state.sortBy.pending = sortBy;
  },
  SET_SORT_BY_FREE(state, sortBy) {
    state.sortBy.free = sortBy;
  },
  SET_SORT_BY_DETAILS(state, sortBy) {
    state.sortBy.details = sortBy;
  },
  SET_SORT_DESC_ACTIVE(state, sortDesc) {
    state.sortDesc.active = sortDesc;
  },
  SET_SORT_DESC_PENDING(state, sortDesc) {
    state.sortDesc.pending = sortDesc;
  },
  SET_SORT_DESC_FREE(state, sortDesc) {
    state.sortDesc.free = sortDesc;
  },
  SET_SORT_DESC_DETAILS(state, sortDesc) {
    state.sortDesc.details = sortDesc;
  },
  SET_ACTIVE_TAB(state, activeTab) {
    state.activeTab = activeTab;
  },
  SET_METRICS(state, metrics) {
    state.metrics = metrics;
  }
};

const actions = {
  async retrieveCondominiums({ commit, rootGetters }) {
    try {
      const parameters = {};
      if (
        rootGetters["auth/isDomiUser"] &&
        rootGetters["filter/getAdministratorSelected"] !== null
      ) {
        parameters.administratorId =
          rootGetters["filter/getAdministratorSelected"]._id;
      }

      const response = await services.CondominiumService.retrieveCondominiums(
        parameters
      );
      commit("SET_CONDOMINIUMS", response);
      commit(
        "SET_ACTIVE_CONDOMINIUMS",
        response.filter(c => {
          return c.status === "pro";
        })
      );
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, { fallbackRoute: "Dashboard" });
      return Promise.reject();
    }
  },
  async retrieveCondominium({ commit }, condominiumId) {
    try {
      const response = await services.CondominiumService.retrieveCondominium(
        condominiumId
      );
      commit("SET_CURRENT_CONDOMINIUM", response.condominium);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, { fallbackRoute: "Condominiums" });
      return Promise.reject();
    }
  },
  async deleteCondominium({ commit }, { condominiumId }) {
    try {
      await services.CondominiumService.deleteCondominium(condominiumId);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, { fallbackRoute: "Condominiums" });
      return Promise.reject();
    }
  },
  async retrieveCondominiumsByFilters({ commit, getters, rootGetters }) {
    try {
      const parameters = {
        search: getters.getFilterSearch
      };

      if (rootGetters["filter/getAdministratorSelected"]) {
        parameters.administratorId =
          rootGetters["filter/getAdministratorSelected"]._id;
      }
      if (getters.getFilterCondominiumStatus) {
        parameters.status = getters.getFilterCondominiumStatus;
      }
      const response = await services.CondominiumService.retrieveCondominiums(
        parameters
      );
      commit("SET_FILTERED_CONDOMINIUMS", response);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, { fallbackRoute: "Dashboard" });
      return Promise.reject();
    }
  },
  async createCondominium({ commit }, condominium) {
    try {
      await services.CondominiumService.createCondominium(condominium);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async updateCondominium({ commit }, { condominiumId, payload }) {
    try {
      await services.CondominiumService.updateCondominium(
        condominiumId,
        payload
      );
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveCondominiumUsers({ commit, getters }) {
    try {
      const response = await services.UserService.retrieveUsers({
        condominiumId: getters.getCurrentCondominium?._id,
        type: ["consumer"]
      });
      commit("SET_CONDOMINIUM_USERS", response);
    } catch (error) {
      let messageText = null;
      if (
        error.response?.body?.code === 404 &&
        error.response?.body?.errorCode === 763
      )
        messageText = "page.issue.noCondominiumErrorMsg";
      console.log(error.response?.body || error);
      SET_ERROR(error, { messageText });
      return Promise.reject();
    }
  },
  async retrieveCondominiumIssues({ commit, getters }) {
    try {
      const response = await services.IssueService.retrieveUserIssues({
        condominiumId: getters.getCurrentCondominium?._id
      });
      commit("SET_CONDOMINIUM_ISSUES", response);
    } catch (error) {
      let messageText = null;
      if (
        error.response?.body?.code === 404 &&
        error.response?.body?.errorCode === 763
      )
        messageText = "page.issue.noCondominiumErrorMsg";
      console.log(error.response?.body || error);
      SET_ERROR(error, { messageText });
      return Promise.reject();
    }
  },
  async retrieveCondominiumCommunications({ commit, getters }) {
    try {
      const response = await services.CommunicationService.retrieveUserCommunications(
        {
          condominiumId: getters.getCurrentCondominium?._id
        }
      );
      commit("SET_CONDOMINIUM_COMMUNICATIONS", response);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveCondominiumCategories(
    { commit, getters },
    { administratorId = "" }
  ) {
    try {
      const condominiumId = getters.getCurrentCondominium._id;

      const response = await services.ParametersService.retrieveCategories({
        administratorId
      });

      const filteredResponse = response
        .filter(c => c.active)
        .map(category => {
          const assignedInfo = category.assignedTo.find(
            info => info.condominiumId === condominiumId
          );
          return {
            ...category,
            assignedTo: assignedInfo
          };
        });

      commit("SET_CONDOMINIUM_CATEGORIES", filteredResponse);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveCondominiumMaintainers(
    { commit, getters },
    { administratorId = "", type = "" }
  ) {
    try {
      // const response = await services.MaintainerService.retrieveMaintainers({
      //   administratorId,
      //   type
      // });
      const response = await services.UserService.retrieveUsers({
        type: "maintainer",
        administratorId,
        category: type
      });
      commit("SET_CONDOMINIUM_MAINTAINERS", response);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async changeCondominiumOwnership({ commit }, { condominiumId, userId }) {
    try {
      await services.CondominiumService.changeCondominiumOwnership(
        condominiumId,
        userId
      );
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async inviteContacts({ commit }, { condominiumId, payload }) {
    try {
      await services.CondominiumService.inviteContacts(condominiumId, payload);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async inviteUsers({ commit }, { condominiumId, payload }) {
    try {
      await services.CondominiumService.inviteUsers(condominiumId, payload);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveMetrics({ rootGetters, commit, getters }) {
    try {
      const administratorId =
        rootGetters["auth/getAdminInfo"].type === "administrator"
          ? rootGetters["auth/getAdminInfo"]._id
          : rootGetters["filter/getAdministratorSelected"]?._id;
      const res = await services.CondominiumService.retrieveMetrics({
        administratorId,
        condominiumId: getters.getCurrentCondominium?._id
      });
      commit("SET_METRICS", res);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  }
};

export default {
  namespaced: true,
  strict: true,
  state,
  getters,
  mutations,
  actions
};
